<template>
  <div class="header-container">
    <div class="header-warpper">
      <el-form :inline="true" :model="buttonGroup">
        <el-form-item>
          <el-button class="el-icon-plus" v-p="['externaladmin:system:vip:add']" type="primary" @click="addVipLevel"
            >添加会员等级</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button class="setting" v-p="['externaladmin:system:vip:set']" @click="$router.push('/system/vip/settings')"
            >会员设置</el-button
          >
        </el-form-item>
      </el-form>
      <addDialog :visible.sync="dialogVisible" :ruleForm="ruleForm" @refreshPage="refreshPage" />
    </div>
    <div class="search-warpper">
      <el-form :inline="true" :model="query">
        <el-form-item label="等级名称：">
          <el-input
            @change="$emit('search')"
            size="mini"
            clearable
            style="width: 240px"
            placeholder="请输入会员等级名称"
            v-model="query.levelName"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select
            size="mini"
            @change="$emit('search')"
            style="width: 200px"
            v-model="query.isEnabled"
            clearable
            placeholder="请选择状态"
          >
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="创建时间：">
          <el-date-picker
            v-model="query.createTime"
            type="daterange"
            size="mini"
            value-format="yyyy-MM-dd"
            placeholder="请选择创建时间"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 300px"
            @change="$emit('search')"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
            style="height: 32px; width: 48px; padding: 0; margin: 0 0 5px 17px"
            type="primary"
            @click="$emit('search')"
            icon="el-icon-search"
          ></el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import addDialog from './addOrEditDialog.vue'

export default {
  components: { addDialog },
  props: {
    query: {
      type: Object,
      default: () => ({})
    },
    sup_this: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      dialogVisible: false,
      ruleForm: {
        levelID: '',
        levelName: '',
        roleID: [],
        logo: ''
      },
      buttonGroup: {},
      options: [
        {
          value: undefined,
          label: '全部'
        },
        {
          value: 0,
          label: '禁用'
        },
        {
          value: 1,
          label: '启用'
        }
      ]
    }
  },
  methods: {
    async addVipLevel() {
      this.dialogVisible = true
    },
    refreshPage() {
      this.sup_this.init()
    }
  }
}
</script>

<style lang="scss" scoped>
.header-container {
  padding-top: 20px;
  .header-warpper {
    .el-button {
      font-size: 14px;
      border-radius: 4px;
      height: 30px;
      padding: 8px 8px;
    }
    .setting {
      border: 1px solid #3841db;
      color: #3841db;
    }
  }
  .search-warpper {
    display: flex;
    font-size: 14px;
    align-items: center;
    color: #1a1a1a;
    margin: 20px 0;
    background-color: #f7f8fa;
    padding: 20px 20px 5px;
    .el-form-item {
      margin-right: 20px;
    }
  }
}
</style>
