<template>
  <div>
    <el-dialog
      append-to-body
      :title="type === 'add' ? '添加等级' : '修改等级'"
      :visible.sync="visible"
      width="520px"
      :before-close="handleClose"
      class="dialog-warpper"
    >
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
        <el-form-item label="等级名称:" prop="levelName" @submit.native.prevent>
          <el-input
            clearable
            type="text"
            show-word-limit
            style="width: 240px"
            placeholder="请输入会员等级名称"
            v-model="ruleForm.levelName"
            maxlength="9"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="角色:" prop="roleID">
          <el-select style="width: 180px" v-model="ruleForm.roleID" clearable placeholder="请选择角色">
            <el-option v-for="item in roleList" :key="item.value" :label="item.label" :value="item.id">
              {{ item.label }}
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item v-if="!ruleForm.isNormal" label="等级:" prop="levelID">
          <el-select style="width: 180px" v-model="ruleForm.levelID" clearable placeholder="请选择会员等级">
            <el-option v-for="item in levelIDList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="!ruleForm.isNormal" label="图标:" prop="logo">
          <svg-icon :icon-class="currentSvg" style="height: 42px; width: 42px" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCanceled">取消</el-button>
        <el-button :loading="loading" type="primary" @click="handleConfirmed(type)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import RoleSelectList from '@/views/user/module/roleSelectList'
import { deepClone, getDiffData } from '@/components/avue/utils/util'
import { addVip, updateVip } from '@/api/vipLevel'
import { mapGetters } from 'vuex'

export default {
  components: {
    RoleSelectList
  },
  props: {
    type: {
      type: String,
      default: 'add'
    },
    visible: {
      type: Boolean,
      default: false
    },
    ruleForm: {
      type: Object,
      default: {}
    },
    modifiedID: {
      type: Number,
      default: 0
    }
  },
  watch: {
    visible: {
      handler(val) {
        this.ruleFormCopy = val ? { levelID: '', levelName: '', logo: '', roleID: [] } : deepClone(this.ruleForm)
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      loading: false,
      levelIDList: [
        { value: 'v0', label: 'v0' },
        { value: 'v1', label: 'v1' },
        { value: 'v2', label: 'v2' },
        { value: 'v3', label: 'v3' },
        { value: 'v4', label: 'v4' },
        { value: 'v5', label: 'v5' },
        { value: 'v6', label: 'v6' },
        { value: 'v7', label: 'v7' }
      ],
      ruleFormCopy: {
        levelID: '',
        roleID: [],
        levelName: '',
        logo: ''
      },
      rules: {
        levelID: [{ required: true, message: '请选择等级', trigger: 'change' }],
        levelName: [{ required: true, message: '请输入等级名称', trigger: 'blur' }],
        roleID: [{ required: true, message: '请选择角色信息', trigger: 'blur' }]
      },
      currentRoles: []
    }
  },
  methods: {
    handleClose() {
      this.$emit('update:visible', false)
      this.initForm()
      this.$refs.ruleForm.clearValidate()
    },
    handleCanceled() {
      this.$emit('update:visible', false)
      this.initForm()
    },
    initForm() {
      this.$refs.ruleForm.resetFields()
    },
    onSuccessMessage(message) {
      this.loading = false
      this.$emit('update:visible', false)
      this.$emit('refreshPage')
      this.$message.success(`成功${message}会员等级信息`)
    },
    async sendVipRequest(fun, message) {
      try {
        const data = {
          levelName: this.ruleForm.levelName,
          level: this.ruleForm.levelID,
          roleId: this.ruleForm.roleID
        }
        if (this.type == 'edit') {
          Object.assign(data, { id: this.modifiedID })
        }
        const { code } = await fun(data)
        if (code == 0) {
          this.initForm()
          this.onSuccessMessage(message)
        }
      } catch (error) {
        this.loading = false
      }
    },
    handleConfirmed(type) {
      this.$refs.ruleForm.validate(async (valid) => {
        if (!valid) return
        this.loading = true
        const message = type === 'add' ? '添加' : '修改'
        if (!getDiffData(this.ruleForm, this.ruleFormCopy)) {
          this.$emit('update:visible', false)
          this.$message.success(`成功${message}会员等级信息`)
          return
        }
        type == 'add' ? this.sendVipRequest(addVip, message) : this.sendVipRequest(updateVip, message)
      })
    }
  },
  computed: {
    ...mapGetters(['businessRoleList', 'businessRoleListProps']),
    roleList({ businessRoleList, currentRoles }) {
      let businessRoleIds = businessRoleList.map((item) => item.id)
      let tempArr = currentRoles.filter((role) => {
        let isInclude = businessRoleIds.includes(role.id)
        if (!isInclude) {
          role.disabled = true
          return true
        }
      })
      return businessRoleList.concat(tempArr)
    },
    currentSvg() {
      if (!this.levelIDList.length) return
      return this.ruleForm.levelID.length ? this.ruleForm.levelID : this.levelIDList[0].label
    }
  },
  created() {
    if (!this.levelIDList.length) return
    if (this.type == 'add') this.ruleForm.levelID = this.levelIDList[0].label
  }
}
</script>

<style lang="scss" scoped>
.dialog-warpper {
  ::v-deep {
    .el-dialog__title {
      font-size: 14px;
      color: #1a1a1a;
    }
    .el-dialog__header {
      height: 52px;
      line-height: 52px;
      padding: 0 0 0 24px;
      border: 1px solid #dcdee0;
    }
  }
}
</style>
